import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This panel lists all the IVRs.`}</p>
    <p>{`The following tables list the data fields of IVR listing panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Contact Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attempt number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of attempts to reach the customer in a contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DTMF Success Rate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The percentage of DTMF signals that the IVR successfully detects and processes. For example, `}<inlineCode parentName="td">{`100%`}</inlineCode>{` or `}<inlineCode parentName="td">{`72%`}</inlineCode>{`.`}<br />{`A `}<a parentName="td" {...{
              "href": "/glossary/#dtmf-success-rate"
            }}>{`DTMF Success Rate`}</a>{` of 72% means that in every 100 times that the customer pressed a number to navigate a menu, 72 were corrected pressed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwarded to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number to where the IVR forwarded the interaction.`}<br /><br />{`If the `}<em parentName="td">{`IVR status`}</em>{` is `}<inlineCode parentName="td">{`Forwarded to sklill`}</inlineCode>{`, `}<em parentName="td">{`Forwarded to`}</em>{` contains the id of the skill to which the interaction was forwarded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the IVR that handled the interaction. For example, `}<inlineCode parentName="td">{`123456`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the IVR that handled the interaction. For example, `}<inlineCode parentName="td">{`Support`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR duration (s)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration of the interaction, in seconds, while in the IVR. If the IVR forwards the interaction to an operator, this duration doesn't include time spent with the operator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the IVR interaction ended. For example, `}<inlineCode parentName="td">{`2025-01-09 14:42:06`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the IVR interaction started. For example, `}<inlineCode parentName="td">{`2025-01-09 14:41:36`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`None: Engagement Cloud did not classified the interaction. `}</li><li><strong parentName="td">{`Disconnected`}</strong>{`: The customer disconnected the interaction while in the IVS.`}</li><li><strong parentName="td">{`Finished`}</strong>{`: The customer or the IVR finished the interaction.`}</li><li><strong parentName="td">{`Forwarded to skill`}</strong>{`: The IVR forwarded the interaction to an operator.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the last node that handled the interaction. For example, node `}<inlineCode parentName="td">{`18`}</inlineCode>{` or `}<inlineCode parentName="td">{`forward_to_operator`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total of processed nodes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total number of nodes that handled the interaction. For example, `}<inlineCode parentName="td">{`14`}</inlineCode>{`.`}<br />{`If you click on a value on this column, the contact window opens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Trigger point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instant in the interaction that triggers the IVR. For example, `}<inlineCode parentName="td">{`Beginning of the call`}</inlineCode>{` or `}<inlineCode parentName="td">{`Operator transfer`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Recognition Accuracy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/glossary/voice-recognition-accuracy"
            }}>{`Rate of successful voice recognition`}</a>{`. For example, in a voice command, an accuracy of `}<inlineCode parentName="td">{`100%`}</inlineCode>{` means that the IVR could understand all words that the customer said. An accuracy of `}<inlineCode parentName="td">{`50%`}</inlineCode>{` means that out of 4 words the IVR could only understand and process 2 words.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      